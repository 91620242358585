import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { FontLoader, MeshMatcapMaterial } from 'three'

const BEVEL_THICKNESS = 0.03
const BEVEL_SIZE = 0.02
const ORB_CLOUD_RADIUS = 50

/**
 * Base
 */
// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color( 0xffD5fD )

/* Axes helper
const axesHelper = new THREE.AxesHelper()
scene.add(axesHelper)*/

const getRandom = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
}

// Fonts
const fontLoader = new THREE.FontLoader()
fontLoader.load(
    './fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new THREE.TextBufferGeometry(
            'Pierce\nSux\nRocks',
            {
                font: font,
                size: 0.5,
                height: 0.15,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: BEVEL_THICKNESS,
                bevelSize: BEVEL_SIZE,
                bevelOffset: 0,
                bevelSegments: 4
            }
        )
        textGeometry.center()

        const material = new THREE.MeshNormalMaterial()
        material.flatShading = true
        
        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)

        const donutGeometry = new THREE.TorusBufferGeometry(0.3, 0.2, 20, 45)
        const icosahedronGeometry = new THREE.IcosahedronGeometry(0.3)
        const octahedronGeometry = new THREE.OctahedronGeometry(0.3)

        for (let i = 0; i < 1000; i++) {
            var geometry
            switch (getRandom(0,3)) {
                case 0:
                    geometry = donutGeometry
                    break
                case 1:
                    geometry = icosahedronGeometry
                    break
                case 2:
                default:
                    geometry = octahedronGeometry
                    break
            }
            let shape = new THREE.Mesh(geometry, material)

            shape.position.x = (Math.random() - 0.5) * ORB_CLOUD_RADIUS
            shape.position.y = (Math.random() - 0.5) * ORB_CLOUD_RADIUS
            shape.position.z = (Math.random() - 0.5) * ORB_CLOUD_RADIUS

            shape.rotation.x = Math.random() * Math.PI
            shape.rotation.y = Math.random() * Math.PI

            let scale = 1//Math.random()
            shape.scale.set(scale, scale, scale)
            scene.add(shape)
        }
    }
)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()